import { call, put, takeEvery, select } from 'redux-saga/effects'
import { fetchPackageInventory } from './api/inventory'
import { getSailDate } from '../user-selections'
import {
  FETCH_ROUTE_LEG_INVENTORY,
  INVENTORY_FETCH_SUCCESS,
  INVENTORY_FETCH_FAIL,
  ALL_INVENTORY_FETCH_SUCCESS,
  CHANGE_INVENTORY_DATE,
  CHANGE_INVENTORY_LEG,
  SW_INVENTORY_FETCH_SUCCESS,
  SET_ALL_INVENTORY_FETCH_SUCCESS,
} from './consts'
import { sailsInventoriesMutator } from './utils'
import { delay } from 'redux-saga'

function* fetchInventorySaga({ payload: { leg, date = '', time = '' } }) {
  let inventoryDate

  if (date) {
    inventoryDate = date
  } else {
    inventoryDate = yield select(getSailDate)
  }

  try {
    const data = yield call(fetchPackageInventory, { leg, date: inventoryDate, time })

    yield put({
      type: INVENTORY_FETCH_SUCCESS,
      payload: data,
    })

    if (!time && data) {
      const inventories = sailsInventoriesMutator(data)

      yield delay(600)
      yield put({
        type: ALL_INVENTORY_FETCH_SUCCESS,
        payload: {
          inventories,
          sailPackageCode: leg,
        },
        data,
      })
    }
  } catch (e) {
    yield put({
      type: INVENTORY_FETCH_FAIL,
    })
  }
}

function* setAllInventories({ payload: data }) {
  const inventories = sailsInventoriesMutator(data)

  yield put({
    type: SET_ALL_INVENTORY_FETCH_SUCCESS,
    payload: {
      inventories,
      sailPackageCode: data.routeLeg,
    },
    data,
  })
}

function* getInventory() {
  yield takeEvery(FETCH_ROUTE_LEG_INVENTORY, fetchInventorySaga)
  yield takeEvery(CHANGE_INVENTORY_DATE, fetchInventorySaga)
  yield takeEvery(CHANGE_INVENTORY_LEG, fetchInventorySaga)
}

function* getInventoryFromSw() {
  yield takeEvery(SW_INVENTORY_FETCH_SUCCESS, setAllInventories)
}

export function* inventorySaga() {
  yield [getInventory(), getInventoryFromSw()]
}
