import { pathOr } from 'ramda'
import { delay, END, eventChannel } from 'redux-saga'
import { call, put, select, take, takeEvery } from 'redux-saga/effects'

import { resetPasswordFailed, resetPasswordSuccess, setAuth, startSocialAuth } from './actions'
import * as Actions from './consts'

import {
  activate as activateApi,
  checkStatus as checkStatusApi,
  idCartCertificate,
  recover as recoverApi,
  resetPassword,
  signin as signinApi,
  signout as signoutApi,
  signup,
  signup as signupApi,
} from './api'
import { translate } from '../../modules/Common/Translator'
import { knownErrors } from '../../modules/Common/known-errors'
import messages from '../../consts/messages'
import { getCurrentReservationId } from '../reservation/selectors'
import { goToPage, setReservationCustomerInfo } from '../../actions'
import { setUserDetails } from '../user/actions'

const UNAUTHORIZED = 401

const AUTH_MESSAGES_DISPLAY_TIME = 10000
const customDelay = (ms) => new Promise((res) => setTimeout(res, ms))

function* logoutIfUnauthorized(action) {
  const status = pathOr('', ['payload', 'status'], action)

  if (status === UNAUTHORIZED) {
    yield put({ type: Actions.SIGNOUT_SUCCESS })
  }
}

function* clearAuthMessages() {
  yield call(customDelay, AUTH_MESSAGES_DISPLAY_TIME)
  yield put(setAuth({ error: '', message: '' }))
}

function* joinProcess({ payload }) {
  try {
    // join with be enpoints
    yield put({ type: Actions.JOIN_SUCCESS, payload })
  } catch (error) {
    const { data = {} } = error

    yield put({
      type: Actions.JOIN_FAILED,
      payload: { ...error, message: data.message || error.message },
    })
  }
}

function socketMessages({ IDCode, CountryCode }) {
  const protocol = window.location.protocol.includes('https') ? 'wss' : 'ws'
  const ws = new WebSocket(`${protocol}://${window.location.host}/socket`)
  // So host will include the port number, whereas hostname will only return the host name.
  return eventChannel((emitter) => {
    ws.onopen = () => {
      ws.send(
        JSON.stringify({
          type: 'auth',
          IDCode,
          CountryCode,
        })
      )
    }

    ws.onmessage = ({ data }) => {
      emitter(JSON.parse(data))
    }

    ws.onerror = () => {
      emitter({ type: 'error', message: 'WS connection error' })
      emitter(END)
    }

    return () => {
      ws.close()
    }
  })
}

function* smartIdAuth(payload) {
  if (!WebSocket) {
    yield put({
      type: Actions.SIGNIN_FAILED,
      payload: { message: 'WebSocket not supported for this browser' },
    })
    return
  }

  try {
    const chan = yield call(socketMessages, payload)
    const { code, type, message } = yield take(chan)

    if (type === 'error') {
      chan.close()
      console.error('smart id error', message)
      yield put({
        type: Actions.SIGNIN_FAILED,
        payload: { message: translate(messages.signSmartIDLoginError), source: 'smartIdAuth' },
      })
    } else {
      yield put({
        type: Actions.VERIFICATION_CODE_RECEIVED,
        payload: { challengeID: code },
      })
    }

    const { user: userData = {}, type: secondType = '', message: secondMessage = '' } = yield take(chan)
    const { token, ...user } = userData

    if (secondType === 'error') {
      console.error(secondMessage)
      yield put({
        type: Actions.SIGNIN_FAILED,
        payload: { message: translate(messages.signSmartIDLoginInterrupt), source: 'smartIdAuth' },
      })
    } else if (token) {
      yield put({
        type: Actions.SIGNIN_SUCCESS,
        payload: { user, token, joinToken: payload.joinToken },
      })
    }

    chan.close()
  } catch (e) {
    yield put({
      type: Actions.SIGNIN_FAILED,
      payload: { ...e },
    })
  }
}

function* setReservationOwnerOnSignIn() {
  const reservationId = yield select(getCurrentReservationId)
  if (reservationId) {
    yield delay(900)
    yield put(setReservationCustomerInfo())
  }
}

function* mobileIdAuth(payload) {
  const { verificationCode, authenticationHash, personalIdentificationNumber, phoneNumber } = yield call(
    signinApi,
    payload
  )

  yield put({
    type: Actions.VERIFICATION_CODE_RECEIVED,
    payload: { challengeID: verificationCode },
  })

  const userAccount = yield call(checkStatusApi, {
    ...{ authenticationHash, personalIdentificationNumber, phoneNumber },
    source: 'mobilId',
  })

  const { token, ...user } = userAccount

  if (token) {
    yield put({
      type: Actions.SIGNIN_SUCCESS,
      payload: {
        user,
        token,
        joinToken: payload.joinToken,
      },
    })
  } else {
    console.error(userAccount)
    throw new Error('This user session does not contain a token')
  }
}

function* signinProcess({ payload }) {
  try {
    if (payload.social) {
      yield put(startSocialAuth(payload))
      return
    }

    if (payload.source.includes('smartId')) {
      yield call(smartIdAuth, payload)
      return
    }

    if (payload.source.includes('cardId')) {
      const { token, ...user } = yield call(idCartCertificate, payload)
      yield put({
        type: Actions.SIGNIN_SUCCESS,
        payload: { user, token, joinToken: payload.joinToken },
      })
      return
    }

    if (payload.source === 'mobilId') {
      yield mobileIdAuth(payload)
    } else {
      const { token, ...user } = yield call(signinApi, payload)

      yield put({
        type: Actions.SIGNIN_SUCCESS,
        payload: { user, token, joinToken: payload.joinToken },
      })
    }
  } catch (error) {
    const { data = {} } = error
    const { code } = data
    const { email } = payload

    let translatedMessage = translate(messages[knownErrors[code]], { email })

    if (!knownErrors[code] && payload.source === 'mobilId') {
      translatedMessage = translate(messages.mobileIdSignInError)
    }

    if (payload.source === 'cardId') {
      translatedMessage = translate(messages.cardIdSignInError)
    }

    yield put({
      type: Actions.SIGNIN_FAILED,
      payload: { ...error, message: translatedMessage || error.message },
    })
  }
}

function* signupProcess({ payload }) {
  try {
    if (payload.social) {
      yield put(startSocialAuth(payload))
      return
    }

    if (payload.source.includes('smartId')) {
      yield call(smartIdAuth, payload)
      return
    }

    if (payload.source === 'mobilId') {
      yield mobileIdAuth(payload)
    } else {
      const { token, ...user } = yield call(signupApi, payload)

      yield put({
        type: Actions.SIGNUP_SUCCESS,
        payload: { user, token },
      })
    }
  } catch (error) {
    const { data = {} } = error
    const { code } = data

    let translatedMessage = translate(messages[knownErrors[code]])

    if (!knownErrors[code] && payload.source === 'mobilId') {
      translatedMessage = translate(messages.mobileIdSignInError)
    }

    yield put({
      type: Actions.SIGNUP_FAILED,
      payload: { ...error, message: translatedMessage || error.message },
    })
  }
}

function* signoutProcess({ payload }) {
  try {
    yield call(signoutApi, payload)
    yield put({ type: Actions.SIGNOUT_SUCCESS })
  } catch (error) {
    const { data = {} } = error

    yield put({
      type: Actions.SIGNOUT_FAILED,
      payload: { ...error, message: data.message || error.message },
    })
  }
}

function* logoutProcess({ payload }) {
  yield call(signoutApi, payload)
  yield put({ type: Actions.LOGOUT_SUCCESS })
}

function* recoverProcess({ payload: { userName, successMessage: message } }) {
  try {
    yield call(recoverApi, { userName })
    yield put({ type: Actions.RECOVER_SUCCESS, payload: { message } })
  } catch (error) {
    const { data = {} } = error

    let { message } = data
    const { code } = data

    if (code && knownErrors[code]) {
      message = translate(messages[knownErrors[code]], { userName })
    } else {
      message = translate(messages.authGeneralError)
    }

    yield put({
      type: Actions.RECOVER_FAILED,
      payload: { ...error, message: message || error.message },
    })
  }
}

function* resetPasswordProcess({ payload }) {
  try {
    const { token } = payload
    const { ...user } = yield call(resetPassword, payload)
    yield put(resetPasswordSuccess({ token, user }))
    yield put(goToPage('/auth/login'))
  } catch (error) {
    const { data = {} } = error

    let { message } = data
    const { code } = data

    if (code && knownErrors[code]) {
      message = translate(messages[knownErrors[code]])
    } else {
      message = translate(messages.authGeneralError)
    }

    yield put(resetPasswordFailed({ ...error, message }))
  }
}

function* activateProcess({ payload }) {
  try {
    const data = yield call(activateApi, payload)
    yield put({ type: Actions.ACTIVATE_SUCCESS, payload: data })
  } catch (error) {
    const { data = {} } = error

    yield put({
      type: Actions.ACTIVATE_FAILED,
      payload: { ...error, message: data.message || error.message },
    })
  }
}

function* watchSignin() {
  yield takeEvery(Actions.SIGNIN, signinProcess)
}

function* watchSignup() {
  yield takeEvery(Actions.SIGNUP, signupProcess)
}

function* watchSignout() {
  yield takeEvery(Actions.SIGNOUT, signoutProcess)
}

function* watchLogout() {
  yield takeEvery(Actions.LOGOUT, logoutProcess)
}

function* watchRecover() {
  yield takeEvery(Actions.RECOVER, recoverProcess)
}

function* watchResetPassword() {
  yield takeEvery(Actions.RESET_PASSWORD, resetPasswordProcess)
}

function* watchActivate() {
  yield takeEvery(Actions.ACTIVATE, activateProcess)
}

function* watchJoin() {
  yield takeEvery(Actions.JOIN, joinProcess)
}

function* failedLogoutWatcher() {
  yield takeEvery(Actions.SIGNOUT_FAILED, logoutIfUnauthorized)
}

function* watchAuthMessages() {
  yield takeEvery(
    [
      Actions.JOIN_FAILED,
      Actions.SIGNIN_FAILED,
      Actions.SIGNUP_FAILED,
      Actions.SIGNOUT_FAILED,
      Actions.RECOVER_FAILED,
      Actions.RECOVER_SUCCESS,
      Actions.ACTIVATE_SUCCESS,
      Actions.ACTIVATE_FAILED,
      Actions.RESET_PASSWORD_FAILED,
      Actions.RESET_PASSWORD_SUCCESS,
      Actions.CARD_ID_PLUGIN_FAILED,
      Actions.TOKEN_CHECK_ERROR,
    ],
    clearAuthMessages
  )
}

function* watchAuthSuccess() {
  yield takeEvery(Actions.SIGNIN_SUCCESS, setReservationOwnerOnSignIn)
}

export function* authSaga() {
  yield [
    watchSignin(),
    watchSignup(),
    watchSignout(),
    watchLogout(),
    watchRecover(),
    watchActivate(),
    watchAuthMessages(),
    watchJoin(),
    failedLogoutWatcher(),
    watchAuthSuccess(),
    watchResetPassword(),
  ]
}
