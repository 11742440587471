import { handleActions } from 'redux-actions'
import * as Actions from '../consts'
import messages from '../../../consts/messages'
import { translate } from '../../../modules/Common/Translator'

const defaultAuthState = {
  token: '',
  joinToken: '',
  error: '',
  message: '',
  isFetching: false,
}

const setAuth = (state, { payload }) => ({ ...state, ...payload })

const setMessage = (state, { payload = {} }) => {
  let translateIdKey = ''
  if (payload.status) {
    translateIdKey = `signupStatus${payload.status.toUpperCase()}`
  }
  return {
    ...state,
    isFetching: false,
    message: payload.message || `${payload.email}: ${translate(messages[translateIdKey])}`,
  }
}

const setErrorMessage = (state, { payload = {} }) => ({
  ...state,
  isFetching: false,
  error: payload.message,
  challengeID: null,
})

const setToken = (state, { payload: { token, joinToken } }) => ({
  ...state,
  token,
  joinToken,
  isFetching: false,
  error: '',
  challengeID: null,
})

const setVerificationCode = (state, { payload: { challengeID } }) => ({
  ...state,
  challengeID,
  isFetching: false,
  error: '',
})

const setFetching = (state) => ({ ...state, isFetching: true, error: '' })

const signin = (state) => ({ ...state, isFetching: true, error: '', message: '' })

const auth = handleActions(
  {
    [Actions.JOIN]: setFetching,
    [Actions.SIGNIN]: signin,
    [Actions.SIGNUP]: setFetching,
    [Actions.SIGNOUT]: setFetching,
    [Actions.ACTIVATE]: setFetching,

    [Actions.SIGNIN_SUCCESS]: setToken,
    [Actions.SIGNUP_SUCCESS]: setToken,
    [Actions.VERIFICATION_CODE_RECEIVED]: setVerificationCode,
    [Actions.SIGNOUT_SUCCESS]: () => defaultAuthState,
    [Actions.LOGOUT_SUCCESS]: () => defaultAuthState,

    [Actions.RECOVER_SUCCESS]: setMessage,
    [Actions.ACTIVATE_SUCCESS]: setMessage,

    [Actions.JOIN_FAILED]: setFetching,
    [Actions.SIGNIN_FAILED]: setErrorMessage,
    [Actions.SIGNUP_FAILED]: setErrorMessage,
    [Actions.SIGNOUT_FAILED]: setErrorMessage,
    [Actions.RECOVER_FAILED]: setErrorMessage,
    [Actions.ACTIVATE_FAILED]: setErrorMessage,
    [Actions.TOKEN_CHECK_ERROR]: setErrorMessage,
    [Actions.RESET_PASSWORD_FAILED]: setErrorMessage,
    [Actions.CARD_ID_PLUGIN_FAILED]: setErrorMessage,

    [Actions.SET_AUTH]: setAuth,
  },
  defaultAuthState
)

export default auth
