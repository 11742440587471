import { createAction } from 'redux-actions'
import * as Actions from '../consts'

export const join = createAction(Actions.JOIN)
export const signin = createAction(Actions.SIGNIN)
export const signup = createAction(Actions.SIGNUP)
export const signout = createAction(Actions.SIGNOUT)
export const logout = createAction(Actions.LOGOUT)
export const recover = createAction(Actions.RECOVER)
export const activate = createAction(Actions.ACTIVATE)

export const joinSuccess = createAction(Actions.JOIN_SUCCESS)
export const signinSuccess = createAction(Actions.SIGNIN_SUCCESS)
export const signupSuccess = createAction(Actions.SIGNUP_SUCCESS)
export const signoutSuccess = createAction(Actions.SIGNOUT_SUCCESS)
export const logoutSuccess = createAction(Actions.LOGOUT_SUCCESS)
export const recoverSuccess = createAction(Actions.RECOVER_SUCCESS)
export const activateSuccess = createAction(Actions.ACTIVATE_SUCCESS)
export const signinFailed = createAction(Actions.SIGNIN_FAILED)

export const resetPassword = createAction(Actions.RESET_PASSWORD)
export const resetPasswordFailed = createAction(Actions.RESET_PASSWORD_FAILED)
export const tokenCheckFailed = createAction(Actions.TOKEN_CHECK_ERROR)
export const resetPasswordSuccess = createAction(Actions.RESET_PASSWORD_SUCCESS)

export const startSocialAuth = createAction(Actions.START_SOCIAL_AUTH)
export const authByToken = createAction(Actions.AUTH_BY_TOKEN)

export const cardIdPluginFailed = createAction(Actions.CARD_ID_PLUGIN_FAILED)

export const setAuth = createAction(Actions.SET_AUTH)
